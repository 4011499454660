<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="200px" style="width:95%;overflow:auto">
          <el-form-item label="系统类型" prop="type">
            <el-select
              :disabled="row.id ? true : false"
              class='each'
              v-model="form.type"
              @change="chooseType"
              placeholder="请选择系统类型">
              <el-option
                v-for="item in systemTypeEnumOptions"
                :key="item.code"
                :label="item.desc"
                :value="item.code">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="冰箱温度范围" prop="minTemperature">
            <el-input type="number" v-model="form.minTemperature" placeholder="请输入最低温"></el-input>
            &nbsp;&nbsp;&nbsp;&nbsp;<span>~</span> &nbsp;&nbsp;&nbsp;&nbsp;
            <el-input type="number" v-model="form.maxTemperature" placeholder="请输入最高温"></el-input>
          </el-form-item>
          <el-form-item :label="'芯片' + (index + 1)"
            v-for="(domain, index) in form.chipList"
            :key="index"
            :prop="'chipList.' + index + '.id'"
            :rules="{ required: true, message: '芯片信息不能为空', trigger: 'blur' }"
          >
            <el-select placeholder="请选择芯片类型" v-model="domain.type"
              class='each'
              clearable>
              <el-option
                v-for="item in chipOptions"
                :key="item.code"
                :label="item.typeName"
                :value="item.code">
              </el-option>
            </el-select>
            <el-input placeholder="请输入芯片ID" v-model="domain.id"></el-input>
            <el-button @click.prevent="removeDomain(domain)" v-if="index > 0">删除</el-button>
          </el-form-item>
          <i class="el-icon-plus" @click="addDomain"></i>
          <div class="btns">
              <el-button @click="close('form')">取消</el-button>
              <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
          </div>
        </el-form>
    </section>
  </template>
  
  <script>
  import { addMachineSystem,getsystemTypeEnum,getChipType } from '@/api/public.js';
  import { validateVersion } from '@/api/publicFun.js';
  export default {
    props:['row'],
    data() {
      // 同时校验最低温与最高温不得为空
      const validateFields = (rule, value, callback)=> {
        if (this.form.minTemperature && this.form.maxTemperature) {
          callback()
        }else{
          callback(new Error('最低温度和最高温度皆不得为空'));
        }
      }
      return {
          form:{ // 表单数据
            chipList:[{type:'',id:'',}],
            minTemperature:null,
            maxTemperature:null,
          },
          versionOptions:[ // 系统版本选项枚举
              {id:1,version:'V1.0.0'},
              {id:2,version:'V1.1.0'},
              {id:3,version:'V1.1.1'},
          ],
          chipOptions:[], // 芯片类型枚举
          systemTypeEnumOptions:[], // 系统类型枚举
          rules:{
            type:[
               { required: true, message: '请选择系统类型', trigger: 'change' }
            ],
            // minTemperature:[
            //   { required:true,validator:validateFields, trigger:'blur'},
            // ]
          }, // 表单验证
          cabinetMachineId:null, // 当前柜机类型
          cabinetMachineType:null, // 当前柜机类型
      }
    },
  
    components: {},
  
    computed: {},
  
    mounted() {
      this.cabinetMachineId = this.row.cabinetMachineId;
      this.cabinetMachineType = this.row.cabinetMachineType;
      this.init();
    },
  
    methods: {
      // 获取系统类型、芯片类型
      async init(){
        await getsystemTypeEnum({param:this.cabinetMachineType}).then(res=>{
          if(res.success){
            this.systemTypeEnumOptions = res.data;
          }else{
            this.systemTypeEnumOptions = [];
          }
        })
        await getChipType({param:this.form.type}).then(res=>{
          if(res.success){
            this.chipOptions = res.data;
          }else{
            this.chipOptions = [];
          }
        })
      },
      // 关闭弹框
      close(){
         this.$emit('close');
      },
      // 新增芯片
      addDomain() {
        this.form.chipList.push({ id:"",type:"" });
      },
      // 删除芯片
      removeDomain(item) {
        var index = this.form.chipList.indexOf(item);
        if (index !== -1) {
          this.form.chipList.splice(index, 1)
        }
      },
      // 选择系统类型
      async chooseType(){
        // 系统更换之后，芯片列表置空
        this.form.chipList = [{type:'',id:'',}];
        await getChipType({param:this.form.type}).then(res=>{
          if(res.success){
            this.chipOptions = res.data;
          }else{
            this.chipOptions = [];
          }
        })
      },
      // 新增
      async add(){
        let data = {
          param: {
            cabinetMachineId: this.cabinetMachineId,
            chipStr:JSON.stringify(this.form.chipList),
            type: this.form.type,
            version: this.form.version,
            minTemperature:this.form.minTemperature,
            maxTemperature:this.form.maxTemperature,
          }
        }
        await addMachineSystem(data).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success'
            });
            this.$emit('close');
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            });
          }
        })
      },
      // 编辑
      edit(){
        let data = {
          param: {
            cabinetMachineId: this.cabinetMachineId,
            chipStr:JSON.stringify(this.form.chipList),
            type: this.form.type,
            version: this.form.version
          }
        }
        addMachineSystem(data).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'success'
            });
            this.$emit('close');
          }else{
            this.$message({
              message:res.msg,
              type:'fail'
            });
          }
        })
      },
      // 提交
      submit(){
        this.$refs["form"].validate(valid => {
            if (valid) {
              this.$confirm('确认提交吗？').then(()=>{
                if(this.row.id){
                  this.edit();
                }else{
                  this.add();
                }
              })
            } else {
                return false;
            }
        });
      },
    },
  };
  </script>
  <style scoped lang="scss">
    .btns{
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    .el-icon-plus{
      font-size: 40px;
      margin-bottom: 20px;
      cursor: pointer;
    }
  </style>