<template>
  <div class="outContainer">
    <div class="back">
      <i class="el-icon-back" @click="back"></i>
    </div>
    <div class="add">
      <el-button type="primary" size="mini" @click="openDrawer('add')"
        >新增</el-button
      >
    </div>
    <!-- 表格数据 -->
    <div class="tableContainer">
      <el-table :data="tableList" height="100%" default-expand-all>
        <!-- 扩展项:芯片列表 -->
        <el-table-column type="expand">
          <template slot-scope="scope">
            <el-table
              :data="scope.row.chipVOList"
              :header-cell-style="{ background: '#ddd' }"
              border
            >
              <el-table-column align="center" label="芯片类型" prop="typeName">
              </el-table-column>
              <el-table-column
                align="center"
                label="芯片初始化状态"
                prop="isInit"
              >
                <template slot-scope="scope">
                  <span style="margin-right: 5px">{{
                    scope.row.isInit == 1 ? '已初始化' : '未初始化'
                  }}</span>
                  <el-button size="mini" @click="initChip(scope.row)"
                    >初始化</el-button
                  >
                </template>
              </el-table-column>
              <el-table-column align="center" label="芯片ID" prop="id">
              </el-table-column>
              <el-table-column align="center" label="在线状态" prop="status">
                <template slot-scope="scope">
                  <p>
                    {{
                      scope.row.status == 1
                        ? '在线'
                        : scope.row.status == 2
                        ? '离线'
                        : '--'
                    }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column align="center" label="版本" prop="version">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.version ? scope.row.version : '--'
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="类型描述" prop="typeDesc">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.typeDesc ? scope.row.typeDesc : '--'
                  }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column align="center" label="操作" fixed="right">
                <template slot-scope="scope">
                  <el-button size="mini" @click="initChip(scope.row)"
                    >初始化</el-button
                  >
                </template>
              </el-table-column> -->
            </el-table>
          </template>
        </el-table-column>
        <el-table-column align="center" label="系统名称" prop="typeName">
        </el-table-column>
        <el-table-column
          align="center"
          label="冰箱设定温度范围"
          prop="temperatureRange"
        >
          <template slot-scope="scope">
            <span style="margin-right: 5px; display: inline-block"
              >{{
                scope.row.minTemperature ? scope.row.minTemperature + '℃' : '0℃'
              }}
              ~
              {{
                scope.row.maxTemperature ? scope.row.maxTemperature + '℃' : '0℃'
              }}</span
            >
            <el-button size="mini" @click="handleTemperatureRange(scope.row)"
              >设置范围</el-button
            >
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="冰箱温度"
          prop="refrigeratorTemperature"
        >
          <template slot-scope="scope">
            <p>
              {{
                scope.row.refrigeratorTemperature ||
                scope.row.refrigeratorTemperature == 0
                  ? scope.row.refrigeratorTemperature + '℃'
                  : '--'
              }}
            </p>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="锁定状态"
          prop="refrigeratorTemperature"
        >
          <template v-slot="{ row }">
            {{ row.locked == 1 ? '锁定' : '未锁定' }}
            <el-button size="mini" @click="unlock(row)">解锁</el-button>
          </template>
        </el-table-column>
        <el-table-column
          min-width="100px"
          align="center"
          label="操作"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button size="mini" @click="allHoming(scope.row)"
              >初始化归位</el-button
            >
            <!-- <el-button size="mini" @click="unlock(scope.row)">解锁</el-button> -->
            <el-button size="mini" @click="equipDetail(scope.row)"
              >设备详情</el-button
            >
            <el-button size="mini" @click="delSystem(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增/编辑 -->
    <el-drawer
      title="新增"
      :visible.sync="addDrawer"
      :before-close="handleClose"
      size="50%"
    >
      <add v-if="addDrawer" @close="handleClose" ref="newForm" :row="row"></add>
    </el-drawer>
    <!-- 冰箱温度范围 -->
    <el-dialog
      title="设置冰箱温度范围"
      :visible.sync="temperatureRangeDialog"
      width="50%"
      append-to-body
    >
      <el-form
        label-width="130px"
        style="display: flex; flex-wrap: wrap"
        :rules="temperatureRule"
      >
        <el-form-item label="最低温" prop="minTemperature">
          <el-input
            type="number"
            v-model="temperatureRangeInfo.minTemperature"
            placeholder="请输入最低温"
          ></el-input>
        </el-form-item>
        <el-form-item label="最高温" prop="maxTemperature">
          <el-input
            type="number"
            v-model="temperatureRangeInfo.maxTemperature"
            placeholder="请输入最高温"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="temperatureRangeDialog = false">关闭</el-button>
        <el-button @click="submitTemperature">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  delMachineSystem,
  getMachineSystemList,
  initCabinetChip,
  maintainSystem,
  updateMachineSystem,
  setAllCabinetDevicesToPosition,
  unlockCabinet,
  getDeviceList,
  resetSingleCabinetDevice,
} from '@/api/public.js'
import add from './add.vue'
export default {
  data() {
    return {
      tableList: [], // 表格数据
      addDrawer: false, // 新增弹框是否打开
      row: null, // 当前操作数据信息
      cabinetId: null, // 柜机ID
      temperatureRangeInfo: {}, // 设置冰箱温度范围绑定表单数据
      temperatureRangeDialog: false, // 设置冰箱温度范围弹框是否显示
      temperatureRule: {}, // 设置温度范围表单验证
    }
  },

  components: {
    add,
  },

  computed: {},

  mounted() {
    this.cabinetId = this.$route.query.cabinetId
    this.$nextTick(() => {
      this.init()
    })
  },

  methods: {
    // 获取柜机系统列表
    async init() {
      await getMachineSystemList({ param: this.cabinetId }).then(res => {
        if (res.success) {
          this.tableList = res.data
        } else {
          this.tableList = []
        }
      })
    },
    // 打开侧边抽屉
    openDrawer(row) {
      if (row == 'add') {
        this.row = {
          cabinetMachineId: this.cabinetId,
          cabinetMachineType: this.$route.query.type,
        }
      } else {
        this.row = row
        this.row.cabinetMachineId = this.cabinetId
        this.row.cabinetMachineType = this.$route.query.type
      }
      this.addDrawer = true
    },
    // 关闭侧边抽屉
    handleClose() {
      this.addDrawer = false
      this.row = null
      this.init()
    },
    // 前往查看设备详情
    equipDetail(row) {
      this.$router.push({
        path: '/cabinetList/equipDetail',
        query: { cabinetId: this.cabinetId, systemId: row.id },
      })
    },
    // 维护、修复
    recover(row) {
      this.$confirm('确认进行该操作吗？').then(async () => {
        let data = {
          param: {
            status: row.status == 1 ? 3 : 1,
            systemId: row.id,
          },
        }
        await maintainSystem(data).then(res => {
          if (res.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'fail',
            })
          }
          this.init()
        })
      })
    },
    // 删除系统
    delSystem(row) {
      this.$confirm('确认删除该系统吗？').then(async () => {
        await delMachineSystem({ param: row.id }).then(res => {
          if (res.success) {
            this.$message({
              message: '删除成功！',
              type: 'success',
            })
            this.init()
          } else {
            this.$message({
              message: res.msg,
              type: 'fail',
            })
            this.init()
          }
        })
      })
    },
    // 初始化芯片状态
    initChip(row) {
      this.$confirm('确认进行该操作吗？').then(async () => {
        await initCabinetChip({ param: row.id }).then(res => {
          if (res.success) {
            this.$message({
              message: '操作成功',
              type: 'success',
            })
          } else {
            this.$message({
              message: res.msg,
              type: 'fail',
            })
          }
          this.init()
        })
      })
    },
    // 打开设置冰箱温度范围弹框
    handleTemperatureRange(row) {
      this.temperatureRangeInfo = { ...row }
      this.temperatureRangeDialog = true
    },
    // 提交温度范围
    async submitTemperature() {
      await updateMachineSystem({ param: this.temperatureRangeInfo }).then(
        res => {
          if (res.success) {
            this.$message({
              message: '提交成功',
              type: 'success',
            })
            this.temperatureRangeDialog = false
            this.init()
          } else {
            this.$message({
              message: res.msg,
              type: 'fail',
            })
          }
        }
      )
    },
    // 返回上一页
    back() {
      this.$router.go(-1)
    },
    // 设备归位
    async homing(row) {
      await resetSingleCabinetDevice({ param: row.id }).then(res => {
        console.log(res, 'res')
        if (res.success) {
          this.init()
          this.$message({
            message: row.name + '归位成功',
            type: 'success',
          })
        } else {
          this.$message({
            // message: '设备归位失败',
            message: row.name + '归位失败',
            type: 'error',
          })
        }
      })
    },
    // 全部归位
    async allHoming(row) {
      let data = {
        param: row.id,
      }
      let matchArr = ['圆盘控制器', '化验圆盘', '化验杯选择圆盘']
      await getDeviceList(data).then(res => {
        if (res.success) {
          console.log(res.data)
          let arr = res.data.filter(item => {
            return matchArr.find(matchArrItem => {
              return matchArrItem === item.name
            })
          })
          arr.forEach(element => {
            this.homing(element)
          })
        } else {
          this.$message({
            message: '设备归位失败',
            type: 'error',
          })
        }
      })
      return
    },
    // 解锁
    async unlock(row) {
      await unlockCabinet({ param: row.id }).then(res => {
        if (res.success) {
          this.$message({
            message: '柜机解锁成功',
            type: 'success',
          })
          this.init()
        } else {
          this.$message({
            message: '柜机解锁失败',
            type: 'error',
          })
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.add {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}
/deep/ .el-form-item__content {
  display: flex;
  align-items: center;
  .el-button--mini {
    margin-left: 5px;
  }
}
</style>
